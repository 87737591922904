import { graphql, useStaticQuery } from 'gatsby'

export const getPostById = (id: string): string => {
  const data = useStaticQuery<GatsbyTypes.GetPostByIdQuery>(graphql`
    query GetPostById {
      allMarkdownRemark(
        limit: 5
      ) {
        edges {
          node {
            id
            html
          }
        }
      }
    }
  `);
  const post = data.allMarkdownRemark.edges.find(({ node }) => node.id === id);
  let html = "";
  if (post && post.node?.html) {
    html = post.node.html;
  }

  return html;
}
