import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import PostContent from '../components/post-content'
import Motd from '../components/motd'

interface IndexPageProps {
  data: GatsbyTypes.allMdPagesQueryQuery;
}

const IndexPage = ({ data }: IndexPageProps) => {
  const { edges } = data.allMarkdownRemark;
  const [elementsArray, setElementsArray] = React.useState<JSX.Element[]>([]);
  const [showContent, setShowContent] = useState<boolean>(false);
  const linkClick = (e: any) => {
    setElementsArray([<PostContent key={`post-${e.target.dataset?.link}-${Math.random()}`} postId={e.target.dataset?.link}></PostContent>]);
  }

  useEffect(() => {
    // const objDiv = document.getElementById("mainWindow");
    // if (objDiv !== null) {
    //   const height = objDiv.scrollHeight;
    //   let count = 0;
    //   while (objDiv.scrollTop < height) {
    //     objDiv.scrollTop += 1;
    //     console.log(objDiv.scrollTop, height);
    //     count++;
    //     if (count > 10000) {
    //       break;
    //     }
    //   }
    //   // objDiv.scrollTop = objDiv.scrollHeight;
    // }
  }, [elementsArray]);
  const mutableEdges = [...edges];
  const Links = mutableEdges
    .sort((nodeA, nodeB) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return nodeB.node.parent.mtimeMs - nodeA.node.parent.mtimeMs;
    })
    .map(edge => <div key={edge.node.id}><button data-link={edge.node.id} onClick={linkClick}>{edge.node.excerpt}</button></div>)

  return <Layout>
          <SEO title="andrewf.net" />
          <Motd callback={() => setShowContent(true)}></Motd>
          {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>*/}
          {/*  <Image />*/}
          {/*</div>*/}
          <div>&nbsp;</div>
          <div className={showContent ? '' : 'hide-content'}>
            It&apos;s not a blog. I love blogs, I just can&apos;t keep one.
            Some random thoughts may appear here from time to time. Or maybe not. I can&apos;t promise anything. Just look if there&apos;re links below.
          </div>
          <div>&nbsp;</div>
          <div className={showContent ? '' : 'hide-content'}>
            {Links}
          </div>
          {/*<div id="main-content" style={{height: "200px", overflow: "scroll"}}>*/}
          <div id="elementsPlace" className={showContent ? '' : 'hide-content'}>
            {elementsArray}
          </div>
          {elementsArray.length > 0 &&
            <button onClick={() => setElementsArray([])}>Go back</button>
          }
          {/*</div>*/}
        </Layout>
}

export default IndexPage

export const pageQuery = graphql`
  query allMdPagesQuery {
    allMarkdownRemark(limit: 5) {
      edges {
        node {
          id
          excerpt(pruneLength: 40)
          parent {
            ... on File {
              mtimeMs
            }
          }
        }
      }
    }
  }
`
