import React from 'react'
import { getPostById } from '../controllers/getPost'

interface PostContentProps {
  postId: string;
}

const PostContent = ({ postId }: PostContentProps) => {

  const html = getPostById(postId);

  return <div>
    <div
      className="blog-post-content"
      dangerouslySetInnerHTML={{ __html: html as string }}
    />
  </div>
}

export default PostContent;
