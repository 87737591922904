import React, { useEffect, useState } from 'react'

import Typed from 'typed.js'

/* eslint no-irregular-whitespace: 0 */
// eslint-disable-next-line @typescript-eslint/camelcase
const ASCII_ART = ` █████  ███    ██ ██████  ██████  ███████ ██     ██ ███████ ███    ██ ███████ ████████ 
██   ██ ████   ██ ██   ██ ██   ██ ██      ██     ██ ██      ████   ██ ██         ██    
███████ ██ ██  ██ ██   ██ ██████  █████   ██  █  ██ █████   ██ ██  ██ █████      ██    
██   ██ ██  ██ ██ ██   ██ ██   ██ ██      ██ ███ ██ ██      ██  ██ ██ ██         ██    
██   ██ ██   ████ ██████  ██   ██ ███████  ███ ███  ██ ██   ██   ████ ███████    ██    
                                                                                       
                                                                                       `;
const ASCII_SMALL = `               _                 __ 
  __ _ _ _  __| |_ _ _____ __ __/ _|
 / _\` | ' \\/ _\` | '_/ -_) V  V /  _|
 \\__,_|_||_\\__,_|_| \\___|\\_/\\_/|_|  
    _ _  ___| |_                    
  _| ' \\/ -_)  _|                   
 (_)_||_\\___|\\__|                   
                                    `;

interface MotdProps {
  callback: () => void;
}

const Motd = ( { callback }: MotdProps ) => {
  let typed: Typed;
  let typedElement: HTMLElement | null;
  const [showMotd, setShowMotd] = useState<boolean>(false);
  useEffect(() => {
      const options = {
        stringsElement: '#stringsList',
        typeSpeed: 80,
        startDelay: 1400,
        cursorChar: '█',
        onComplete: (self: any) => {
          self.cursor.hidden = true;
          setShowMotd(true);
          if (callback) {
            callback();
          }
        }
      };
      // this.el refers to the <span> in the render() method
      if (typedElement) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        typed = new Typed(typedElement, options);
      }
      return () => {
        if (typed) {
          typed.destroy();
        }
      }
  }, []);

  return (
    <div id="motd">
      <div>System started</div>
      <div>Guest login granted</div>
      <div>&nbsp;</div>
      <span id="prompt">guest@andrwef.net$ </span><span ref={(el) => { typedElement = el; }}></span><span id="stringsList"><span style={{display: 'none'}}>./welcome^1000</span></span>
      <div className={showMotd ? '' : 'hide-content'}>
        <div id="asciiart"><pre>{ASCII_ART}</pre></div>
        <div id="asciismall"><pre>{ASCII_SMALL}</pre></div>
        <div>Welcome! My name is Andrew. I do web/app stuff for work and (currently) not-so-smart home automation for fun. But hobbies change.</div>
        <div>
          I have a <a href="https://www.twitter.com/andrewf">Twitter</a> and an <a href="https://www.instagram.com/i_am_andrewf/">Instagram</a>.
          I also have a Facebook, but you don&apos;t want it. Believe me.
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  );
}

export default Motd;
